.multi-selected-item {
  display: inline-flex;
  background: lightgray;
  border-radius: 20px;
  padding: 2px 12px;
  font-size: 0.9em;
  margin-bottom: 5px;
  min-width: 70px;
  max-width: 200px;
  justify-content: space-between;
  align-items: center;
  p {
    padding: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &:not(:last-child) {
    margin-right: 5px;
  }
  .mat-icon-button {
    height: 20px;
    width: 20px;
    line-height: 1;
    margin-left: 6px;
    .mat-icon {
      line-height: 20px;
      height: 20px;
      width: 20px;
      color: rgba(0, 0, 0, 0.87);
      opacity: 0.5;
    }
    .material-icons-outlined {
      font-size: 20px;
    }
  }
  &:hover {
    cursor: pointer;
    background: rgb(182, 182, 182);
  }
}
