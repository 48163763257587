@import './flex';

.card-empty {
  color: $sc-gray;
  font-size: 1.2em;
  text-align: center;
}

/** @Depracated */

mat-card.mat-mdc-card.full-table {
  padding: 0;
}

mat-card.mat-mdc-card {
  overflow: hidden;
  border-radius: $card-radius !important;
  box-shadow: $card-shadow !important;
}
.mat-accordion .mat-expansion-panel {
  margin-bottom: 7px;
}
.mat-accordion .mat-expansion-panel {
  border-radius: $card-radius !important;
  box-shadow: $card-shadow !important;
}
mat-card.mat-mdc-card.with-toolbar {
  padding-top: 0;

  .mat-toolbar {
    padding-left: 0;
    padding-right: 0;
  }
}

mat-card.full-content {
  .mat-mdc-card-content {
    padding: 0px 0px 16px 0px;
  }
  &.no-title {
    padding-top: 0;
  }
}

mat-card.mat-mdc-card.input-row-card {
  padding: 12px 24px 4px;
}

mat-card.just-toolbar {
  padding-bottom: 0;
}

mat-card.mat-mdc-card.w-toolbar {
  padding: 0;

  mat-card-content.mat-mdc-card-content {
    padding: 0 16px 16px;
  }

  @media (max-width: 600px) {
    mat-card-content.mat-mdc-card-content {
      margin: 0 -16px -24px;
    }
  }
}

mat-card.no-overflow {
  overflow: initial;
}

.card-padding {
  padding: 0 16px 16px;
}

mat-card.normal-card {
  mat-card-title.mat-mdc-card-title {
    font-size: 20px;
    margin-bottom: 5px;
  }
}

mat-card.dashboard-card.mat-mdc-card {
  @extend .flex-display;
  @extend .flex-align-center;

  flex-direction: row;
  height: 100px;
  text-align: center;
  font-size: 25px;
  padding: 15px;
  position: relative;

  i.top-text {
    position: absolute;
    font-size: 14px;
    color: gray;
    top: 10px;
    left: 10px;
  }

  button.top-button {
    position: absolute;
    top: 5px;
    right: 5px;
    color: gray;

    mat-icon.mat-icon {
      font-size: 24px;
    }
  }

  i.top-date {
    position: absolute;
    text-align: center;
    bottom: 5px;
    left: 0;
    width: 100%;
    font-size: 12px;
    color: gray;
  }

  > span {
    width: 50%;
    padding-top: 5px;
    font-weight: 500;
    &:not(.single):first-child {
      border-right: 1px solid lightgray;
    }

    &.single {
      width: 100%;
    }

    &.small-box {
      width: 30%;
      padding-right: 24px;
    }

    &.normal-align {
      font-size: 0.5em;

      p {
        margin-top: 5px;
      }

      h3 {
        font-size: 1.5em;
        font-weight: 400;
        margin: 0;
      }
    }
  }

  mat-icon.mat-icon.material-icons-outlined {
    display: block;
    align-self: center;
    margin: auto;
    font-size: 30px;
    width: 30px;
    height: 30px;
  }

  p {
    font-size: 17px;
    color: gray;
    margin-top: 15px;
    margin-bottom: 0;
  }
}

mat-card.dashboard-card-2.mat-mdc-card {
  @extend .flex-display;
  @extend .flex-align-center;

  display: flex;
  flex-direction: row;
  height: 160px;
  text-align: center;
  font-size: 30px;
  padding: 15px;
  position: relative;

  @media only screen and (max-width: 1600px) {
    font-size: 25px;
  }

  p.top-text {
    padding: 0;
    margin: 0;
    position: absolute;
    font-size: 14px;
    color: gray;
    top: 0;
    left: 0;
  }

  button.top-button {
    position: absolute;
    top: 5px;
    right: 5px;
    color: gray;

    mat-icon.mat-icon {
      font-size: 24px;
    }
  }

  i.top-date {
    position: absolute;
    text-align: center;
    bottom: 5px;
    left: 0;
    width: 100%;
    font-size: 12px;
    color: gray;
  }

  .card-container {
    @extend .flex-display;
    @extend .flex-center-center;

    position: relative;
    width: 50%;
    padding-top: 5px;
    height: 100%;

    &:not(.right-card-container, .single) {
      border-right: 1px solid lightgray;

      .card-value {
        margin-left: -15px;
      }
    }

    &.right-card-container {
      .card-value {
        margin-right: -15px;
      }

      p.top-text {
        left: 10px;
      }
    }

    &.single {
      width: 100%;
    }

    &.small-box {
      width: 30%;
      padding-right: 24px;
    }

    &.normal-align {
      font-size: 0.5em;

      p {
        margin-top: 5px;
      }

      h3 {
        font-size: 1.5em;
        font-weight: 400;
        margin: 0;
      }
    }

    .card-value {
      height: 100%;
      width: 100%;
      position: relative;
      text-align: center;

      > div {
        width: 100%;
      }

      > div:first-child {
        position: absolute;

        mat-icon.mat-icon.material-icons-outlined {
          width: 40px;
          height: 40px;
          font-size: 40px;
        }

        top: 30%;
        font-weight: 500;
        //left: 50%;
        // transform: translate(-50% , -45%);
        // -webkit-transform: translate(-50%, -45%);
      }
    }

    .sub-text {
      font-size: 14px;
      color: gray;
      text-align: center;

      // left:50%;
      // transform: translate(-50%);
      position: absolute;
      top: 90px;

      &.negative {
        color: $sc-danger;
      }

      &.positive {
        color: $sc-success;
      }

      div {
        font-size: 9px;
        color: gray;
      }

      mat-icon.mat-icon.material-icons-outlined {
        vertical-align: bottom;
        line-height: 19px;
        height: 17px;
        width: 23px;
        font-size: 28px;
        margin-left: -10px;
      }
    }
  }

  button.top-button mat-icon.mat-icon.material-icons-outlined {
    display: block;
    align-self: center;
    margin: auto;
    font-size: 25px;
    width: 25px;
    height: 25px;
  }

  p {
    font-size: 17px;
    color: gray;
    margin-top: 15px;
    margin-bottom: 0;
  }
}

mat-card.mat-mdc-card .card-loading {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3em;
}

mat-card-footer.floating {
  background-color: #fff;
  border-top: 1px solid $sc-light;
  bottom: 0;
  margin: 0;
  padding: 20px 16px;
  position: sticky;
  width: 100%;
  z-index: 100;
}
